import React, { useContext, useMemo } from 'react';
import communicationCenterIcon from '../../../assets/communicationCenter.svg';
import gateAttendantIcon from '../../../assets/gateAttendant.svg';
import guestPortalIcon from '../../../assets/guestPortal.svg';
import hostPortalIcon from '../../../assets/hostPortal.svg';
import pointOfsaleIcon from '../../../assets/pointOfSale.svg';
import reportCenterIcon from '../../../assets/reportCenter.svg';
import residentPortalIcon from '../../../assets/residentPortal.svg';
import securityIcon from '../../../assets/security.svg';
import userSettingIcon from '../../../assets/userSetting.svg';
import vendorPortalIcon from '../../../assets/vendorPortal.svg';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import { getUrlsDynamic } from '../../../common_lib_front/configs/symlivUrls';
import Card from '../../../components/card/card';
import { Footer } from '../../../components/footer/footer';
import style from './home.module.css';

export default function Home(): React.ReactElement {
  const { communityId, config } = useContext(CommunityContext);
  const showDeadLinks = useMemo(() => (communityId === 'palmettodunes'), [communityId]);
  return (
    <div className={style.container}>
      <div className={style.box}>
        <div className={style.box__innerBox}>
          <Card
            img={reportCenterIcon}
            alt="report center img"
            title="Admin Center"
            description="View and monitor all residents, vendors, and guests in your community."
            buttonLabel="View Admin Center"
            href="/admin/report-center"
          />
        </div>
        <div className={style.box__innerBox}>
          <Card
            img={reportCenterIcon}
            alt="report center img"
            title="Smart Access Manager"
            description="Oversee and manage all users access to your community"
            buttonLabel="Oversee and Manage Users"
            href="/admin/rapid-search"
          />
        </div>
        {config.admin?.showOtherPortalLinks && config.guest && (
          <div className={style.box__innerBox}>
            <Card
              img={guestPortalIcon}
              alt="guest portal img"
              title={communityId === 'grandcomplex' ? 'STR Guest Portal' : 'Guest Portal'}
              description={communityId === 'grandcomplex' ? 'View community short-term rental guest portal.' : 'View community guest portal.'}
              buttonLabel="Access Guest Portal"
              href={`${getUrlsDynamic('guest').front}/${communityId}`}
            />
          </div>
        )}
        {config.admin?.showOtherPortalLinks && config.vendor && (
          <div className={style.box__innerBox}>
            <Card
              img={vendorPortalIcon}
              alt="vnedor portal img"
              title="Vendor Portal"
              description="View community vendor portal."
              buttonLabel="Access Vendor Portal"
              href={`${getUrlsDynamic('vendor').front}/${communityId}`}
            />
          </div>
        )}
        {config.admin?.showOtherPortalLinks && config.resident && (
          <div className={style.box__innerBox}>
            <Card
              img={residentPortalIcon}
              alt="resident portal img"
              title={communityId === 'grandcomplex' ? 'Owner Portal' : 'Resident Portal'}
              description={communityId === 'grandcomplex' ? 'View community owner portal.' : 'View community resident portal.'}
              buttonLabel={communityId === 'grandcomplex' ? 'Access Owner Portal' : 'Access Resident Portal'}
              href={`${getUrlsDynamic('resident').front}/${communityId}`}
            />
          </div>
        )}
        {config.admin?.showOtherPortalLinks && config.resident && (
          <div className={style.box__innerBox}>
            <Card
              img={hostPortalIcon}
              alt="host portal img"
              title={communityId === 'grandcomplex' ? 'Property Manager Portal' : 'Host Portal'}
              description={communityId === 'grandcomplex' ? 'View community Property Manager Portal' : 'View community Host portal.'}
              buttonLabel={communityId === 'grandcomplex' ? 'Access Property Manager Portal' : 'Access Host Portal'}
              href={`${getUrlsDynamic('resident').front}/${communityId}`}
            />
          </div>
        )}
        {config.admin?.showOtherPortalLinks && config.autoGateAttendant && (
          <div className={style.box__innerBox}>
            <Card
              img={gateAttendantIcon}
              alt="gate attendant img"
              title="Gate Attendant"
              description="View Virtual Gate Attendant."
              buttonLabel="Access Gate Attendant"
              href={`${getUrlsDynamic('gateAttendant').front}/${communityId}`}
            />
          </div>
        )}
        {showDeadLinks && (
          <div className={style.box__innerBox}>
            <Card
              img={pointOfsaleIcon}
              alt="point of sale img"
              title="Point-of-Sale"
              description="Sell passes or issue refunds to customers."
              buttonLabel="Sell New Passes"
              href="/point-of-sale"
            />
          </div>
        )}
        <div className={style.box__innerBox}>
          <Card
            img={securityIcon}
            alt="security img"
            title="Security"
            description="Scan passes, look up individuals, and create violations, complaints, and incident reports."
            buttonLabel="Access Security"
            href="/admin/security"
          />
        </div>

        {showDeadLinks && (
          <div className={style.box__innerBox}>
            <Card
              img={communicationCenterIcon}
              alt="gate attendant img"
              title="Communication Center"
              description="Communicate with all residents, guests, vendors, and employees in your community."
              buttonLabel="View Communications"
              href="/communication-center"
            />
          </div>
        )}
        {(showDeadLinks || communityId === 'sipoa') && (
          <div className={style.box__innerBox}>
            <Card
              img={userSettingIcon}
              alt="users and settings img"
              title="Users &amp; Settings"
              description="Add or manage users globally."
              buttonLabel="Manage Users"
              href="/admin/report-center/user-settings"
            />
          </div>
        )}
        <div className={style.box__innerBox}>
          <div className={style.cardBox}>
            <div className={style.invisiableBox} />
          </div>
        </div>
        <div className={style.box__innerBox}>
          <div className={style.cardBox}>
            <div className={style.invisiableBox} />
          </div>
        </div>
        <div className={style.box__innerBox}>
          <div className={style.cardBox}>
            <div className={style.invisiableBox} />
          </div>
        </div>

      </div>
      <Footer />
    </div>
  );
}
