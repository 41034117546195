import React, {
  ReactElement, useContext, useMemo, useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import UserProfileButton from '../userProfileButton/userProfileButton';
import style from './header.module.css';
import store, { isLogedIn as checkIsLogedIn } from '../../common_lib_front/utilities/store';
import useContainerBlur from '../../common_lib_front/utilities/useContainerBlur';

import GenericButton from '../genericButton/genericButton';
import useMediaQuery from '../../common_lib_front/utilities/useMediaQuery';
import { ReactComponent as LanguageIcon } from '../../assets/language.svg';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import { getUrlsDynamic } from '../../common_lib_front/configs/symlivUrls';

const HeaderLink = (props: {
  hrefs: Array<string>,
  subTitles: Array<string>,
  title: string,
  active?: boolean,
}) => {
  const {
    hrefs, subTitles, title, active,
  } = props;
  const { open, containerId, setOpen } = useContainerBlur(`header-link-${title}`);

  let boxStyle;
  let openColor;
  if (open) {
    // TODO: fill in stle for active tab here
    boxStyle = style.triangleUp;
    openColor = style.openColor;
  } else {
    // TODO: set style for default tab here
    boxStyle = style.triangleDown;
    openColor = style.closeColor;
  }
  return (
    <div id={containerId} className={`${style.navItem} textColorMain ${openColor}`}>
      <button
        className={style.dropDownBtn}
        onClick={() => setOpen(!open)}
      >
        {title}
        <div className={`${boxStyle} `} />
        {open
          ? (
            <div className={`${style.dropDownMenu} white`}>
              {
                subTitles.map((val: string, idx: number) => {
                  if (hrefs[idx][0] === '/') {
                    return (
                      <Link
                        key={`${containerId}-subLink-${val}`}
                        to={hrefs[idx]}
                        onClick={() => setOpen(false)}
                        className={style.dropDownItems}
                      >
                        {val}
                      </Link>
                    );
                  }
                  return (
                    <a href={hrefs[idx]} className={style.dropDownItems}>{val}</a>
                  );
                })
              }
            </div>
          ) : null}
      </button>

    </div>
  );
};
HeaderLink.defaultProps = {
  active: false,
};

export const Header = (): ReactElement => {
  const [isLogedIn, setIsLogedIn] = useState<boolean>(checkIsLogedIn());
  store.subscribe(() => setIsLogedIn(checkIsLogedIn()));
  const { pathname } = useLocation();
  const allowLinks = !/(\/admin($|\/$)|\/login|\/create-account|\/forgot-password)/.test(pathname);
  const [showLinks, setShowLinks] = useState<boolean>(false);
  const smallScreen = useMediaQuery('(max-width: 767px)');
  const {
    logo, subLogo, name: communityName, communityId,
  } = useContext(CommunityContext);
  const showDeadLinks = useMemo(() => communityId === 'palmettodunes', [communityId]);
  // useEffect(() => {
  //   setShowLinks(!/\/admin($|\/$|\/login|\/create-account|\/forgot-password)/.test(pathname));
  // }, [pathname, showLinks]);

  let headerStyle;
  let invisiableBox;
  let profileBtn;
  let logoBox;
  let logoLayout;
  let profileBox;
  let headerColor;
  let logoStyle;
  let languageWithMargin;

  if (isLogedIn) { // any time the user is loged in
    // TODO: set styles for when the UserProfileButton should be displayed
    profileBtn = style.profileBtnApper;
  } else {
    // TODO: set styles for when the UserProfileButton is not displayed
    profileBtn = style.profileBtnDisapper;
  }

  if (showLinks) {
    // TODO: set styles for when navigation links are displayed
    headerStyle = style.header;
    logoBox = style.logoBoxSmall;
    logoLayout = style.logoLeft;
    profileBox = style.profileLogoLeft;
    headerColor = `${headerStyle} mainColor `;
    logoStyle = subLogo;
    profileBtn = style.profileBtnWhite;
    languageWithMargin = style.languageWithMargin;
  } else {
    // TODO: set styles for when navigation links are not shown
    headerStyle = style.headerLogoMiddle;
    invisiableBox = style.invisibleBoxApper;
    logoBox = style.logoBoxLarge;
    logoLayout = style.logoMiddle;
    profileBox = style.profileLogoMiddle;
    headerColor = `${headerStyle} white `;
    logoStyle = logo;
    profileBtn = style.profileBtnBlack;
    languageWithMargin = style.languageWithOutMargin;
  }
  if (allowLinks) {
    headerStyle = style.header;
    logoBox = style.logoBoxSmall;
    logoLayout = style.logoLeft;
    profileBox = style.profileLogoLeft;
    headerColor = `${headerStyle} mainColor `;
    invisiableBox = style.invisibleBoxDisApper;
    logoStyle = subLogo;
    profileBtn = style.profileBtnWhite;
    languageWithMargin = style.languageWithMargin;
  } else {
    // TODO: set styles for when navigation links are not shown
    headerStyle = style.headerLogoMiddle;
    invisiableBox = style.invisibleBoxApper;
    logoBox = style.logoBoxLarge;
    logoLayout = style.logoMiddle;
    profileBox = style.profileLogoMiddle;
    headerColor = `${headerStyle} white `;
    logoStyle = logo;
    profileBtn = style.profileBtnBlack;
    languageWithMargin = style.languageWithOutMargin;
  }

  return (
    <div className={headerColor}>
      {smallScreen && allowLinks
        ? (
          <div className={style.hamburgerBtn}>
            <GenericButton
              title=""
              outline="none"
              color="none"
              icon={showLinks ? 'hambutger-x' : 'hamburger'}
              clickHandler={() => setShowLinks(!showLinks)}
            />
          </div>
        )
        : null}
      <div className={invisiableBox} />
      <Link to="/admin" className={logoBox}>
        {smallScreen
          ? communityName
          : <img src={logoStyle} alt="logo" className={logoLayout} />}
      </Link>

      {allowLinks && (smallScreen ? showLinks : true)
        ? (
          <nav className={style.nav}>
            {/* <HeaderLink
              hrefs={['/admin/report-center']}
              subTitles={['Report Center']}
              title="Reports"
            /> */}
            <Link
              to="/admin/report-center"
              className={`${style.navItem} ${style.padding} textColorWhite `}
            >
              Admin Center
            </Link>
            <Link
              to="/admin/rapid-search"
              className={`${style.navItem} ${style.padding} textColorWhite `}
            >
              Rapid Search
            </Link>
            <HeaderLink
              hrefs={['/admin/security', '/admin/point-of-sale']}
              subTitles={communityId === 'grandcomplex' || communityId === 'sipoa' ? ['Security Portal'] : ['Security Portal', 'Point-of-Sale']}
              title="Security"
            />
            <HeaderLink
              hrefs={communityId === 'grandcomplex'
                ? [`${getUrlsDynamic('guest').front}/${communityId}`, `${getUrlsDynamic('resident').front}/${communityId}`, `${getUrlsDynamic('resident').front}/${communityId}`]
                : [`${getUrlsDynamic('guest').front}/${communityId}`, `${getUrlsDynamic('vendor').front}/${communityId}`, `${getUrlsDynamic('resident').front}/${communityId}`, `${getUrlsDynamic('resident').front}/${communityId}`]}
              subTitles={communityId === 'grandcomplex'
                ? ['Guest', 'Owners', 'Property Manager']
                : ['Guest', 'Vendor', 'Resident', 'Host']}
              title="Portals"
            />
            <Link
              to="/admin/report-center/documents"
              className={`${style.navItem} ${style.padding} textColorWhite `}
            >
              Report Center
            </Link>
            {showDeadLinks && (
              <HeaderLink
                hrefs={['/admin', '/admin', '/admin']}
                subTitles={['Service Requests', 'Complaints', 'Calendar & Reservations']}
                title="Community"
              />
            )}
            {showDeadLinks && (
              <HeaderLink
                hrefs={['/admin/communication-center', '/admin/communication-center/inbox']}
                subTitles={['Communication Center', 'Inbox']}
                title="Communication"
              />
            )}
            <HeaderLink
              hrefs={showDeadLinks
                ? ['/admin/report-center/user-settings', '/admin/billing']
                : ['/admin/report-center/user-settings']}
              subTitles={showDeadLinks
                ? ['Users & Settings', 'Billing']
                : ['Users & Settings']}
              title="Settings"
            />
            {/* <Link to="/admin/support"
            className={`${style.navItem} ${style.padding} textColorWhite `}>
              Support
            </Link> */}
          </nav>
        )
        : null}
      <div className={profileBox}>
        {/* <button className={`${style.language} ${languageWithMargin} textColorWhite
        thirdColor `}
        >
          <span className={style.languageIcon}>
            <LanguageIcon />
          </span>
          espa&#241;ol
        </button> */}
        <div className={profileBtn}>
          {isLogedIn
            ? <UserProfileButton />
            : null}

        </div>

      </div>
    </div>
  );
};

export default Header;
